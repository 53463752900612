import React, { useState, useEffect } from 'react';
import IconAdobe from '../../../assets/icons/IconAdobe';
import IconGithub from '../../../assets/icons/IconGithub';
import { Typography } from 'antd';
import { Collapse, Button, Menu, Dropdown, Space } from 'antd';
import { CaretDownOutlined, CaretUpOutlined, DownOutlined } from '@ant-design/icons';
import { FileOutlined, GithubOutlined } from '@ant-design/icons';
import Icon from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const HeaderPage = () => {

	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
	}, [window.innerWidth]);

	const authors = [
		{ name: "Nathaniel Li", affiliation: "1,2", bioLink: "https://nli0.github.io", contrib: "∗" },
		{ name: "Alexander Pan", affiliation: "2", bioLink: "https://aypan17.github.io/", contrib: "∗" },
		{ name: "Anjali Gopal", affiliation: "3,4", bioLink: "https://www.linkedin.com/in/anjaligopal/", contrib: "†" },
		{ name: "Summer Yue", affiliation: "5", bioLink: "https://www.linkedin.com/in/yutingyue/", contrib: "†" },
		{ name: "Daniel Berrios", affiliation: "5", bioLink: "https://www.linkedin.com/in/danielxberrios/", contrib: "†" },
		{ name: "Alice Gatti", affiliation: "1", bioLink: "https://www.linkedin.com/in/gattialice/", contrib: "‡" },
		{ name: "Justin D. Li", affiliation: "1,6", bioLink: "https://www.linkedin.com/in/justindli/", contrib: "‡" },
		{ name: "Ann-Kathrin Dombrowski", affiliation: "1", bioLink: "https://scholar.google.com/citations?hl=en&user=YoNVKCYAAAAJ", contrib: "‡" },
		{ name: "Shashwat Goel", affiliation: "1,7", bioLink: "https://shash42.github.io", contrib: "‡" },
		{ name: "Long Phan", affiliation: "1", bioLink: "https://longphan.ai/", contrib: "‡" },
		{ name: "Gabriel Mukobi", affiliation: "8", bioLink: "https://gabrielmukobi.com" },
		{ name: "Nathan Helm-Burger", affiliation: "4", bioLink: "" },
		{ name: "Rassin Lababidi", affiliation: "4", bioLink: "" },
		{ name: "Lennart Justen", affiliation: "3,4", bioLink: "" },
		{ name: "Andrew B. Liu", affiliation: "4,9", bioLink: "" },
		{ name: "Michael Chen", affiliation: "1", bioLink: "" },
		{ name: "Isabelle Barrass", affiliation: "1", bioLink: "https://www.linkedin.com/in/isabelle-barrass-44a36210a/" },
		{ name: "Oliver Zhang", affiliation: "1", bioLink: "https://www.linkedin.com/in/oliver-z-30a16812a/" },
		{ name: "Xiaoyuan Zhu", affiliation: "10", bioLink: "https://www.linkedin.com/in/xiaoyuan-zhu-38005a224/" },
		{ name: "Rishub Tamirisa", affiliation: "11,13", bioLink: "https://www.linkedin.com/in/rishubtamirisa/" },
		{ name: "Bhrugu Bharathi", affiliation: "12,13", bioLink: "https://www.linkedin.com/in/bhrugu-bharathi/" },
		{ name: "Adam Khoja", affiliation: "1,2", bioLink: "" },
		{ name: "Zhenqi Zhao", affiliation: "14", bioLink: "" },
		{ name: "Ariel Herbert-Voss", affiliation: "9,15", bioLink: "" },
		{ name: "Cort B. Breuer", affiliation: "8", bioLink: "" },
		{ name: "Samuel Marks", affiliation: "9", bioLink: "" },
		{ name: "Oam Patel", affiliation: "9", bioLink: "" },
		{ name: "Andy Zou", affiliation: "1,16", bioLink: "https://andyzoujm.github.io/" },
		{ name: "Mantas Mazeika", affiliation: "1,11", bioLink: "https://scholar.google.com/citations?user=fGeEmLQAAAAJ&hl=en" },
		{ name: "Zifan Wang", affiliation: "1", bioLink: "https://zifanw.net" },
		{ name: "Palash Oswal", affiliation: "16", bioLink: "https://oswalpalash.com" },
		{ name: "Weiran Lin", affiliation: "16", bioLink: "https://linweiran.github.io" },
		{ name: "Adam A. Hunt", affiliation: "16", bioLink: "" },
		{ name: "Justin Tienken-Harder", affiliation: "15", bioLink: "" },
		{ name: "Kevin Y. Shih", affiliation: "8", bioLink: "" },
		{ name: "Kemper Talley", affiliation: "17", bioLink: "" },
		{ name: "John Guan", affiliation: "2", bioLink: "" },
		{ name: "Russell Kaplan", affiliation: "5", bioLink: "" },
		{ name: "Ian Steneker", affiliation: "5", bioLink: "" },
		{ name: "David Campbell", affiliation: "5", bioLink: "" },
		{ name: "Brad Jokubaitis", affiliation: "5", bioLink: "" },
		{ name: "Alex Levinson", affiliation: "5", bioLink: "" },
		{ name: "Jean Wang", affiliation: "5", bioLink: "" },
		{ name: "William Qian", affiliation: "5", bioLink: "" },
		{ name: "Kallol Krishna Karmakar", affiliation: "18", bioLink: "" },
		{ name: "Steven Basart", affiliation: "1", bioLink: "https://stevenbas.art/" },
		{ name: "Stephen Fitz", affiliation: "19", bioLink: "https://stephenfitz.net" },
		{ name: "Mindy Levine", affiliation: "20", bioLink: "https://hul.ariel.ac.il/wp/mindy-levine/" },
		{ name: "Ponnurangam Kumaraguru", affiliation: "7", bioLink: "https://precog.iiit.ac.in/" },
		{ name: "Uday Tupakula", affiliation: "18", bioLink: "https://www.newcastle.edu.au/profile/uday-tupakula" },
		{ name: "Vijay Varadharajan", affiliation: "18", bioLink: "https://www.newcastle.edu.au/profile/vijay-varadharajan" },
		{ name: "Yan Shoshitaishvili", affiliation: "21", bioLink: "https://yancomm.net/" },
		{ name: "Jimmy Ba", affiliation: "22", bioLink: "https://jimmylba.github.io/" },
		{ name: "Kevin M. Esvelt", affiliation: "3", bioLink: "https://www.media.mit.edu/people/esvelt/overview/" },
		{ name: "Alexandr Wang", affiliation: "5", bioLink: "https://www.linkedin.com/in/alexandrwang/", contrib: "**" },
		{ name: "Dan Hendrycks", affiliation: "1", bioLink: "https://people.eecs.berkeley.edu/~hendrycks/", contrib: "**" }
	];

	const [activeKey, setActiveKey] = useState(['0']);
	const onCollapseChange = (key) => {
		setActiveKey(key);
	};

	const affiliations = [
		"Center for AI Safety",
		"University of California, Berkeley",
		"Massachusetts Institute of Technology",
		"SecureBio",
		"Scale AI",
		"New York University",
		"IIIT Hyderabad",
		"Stanford University",
		"Harvard University",
		"University of Southern California",
		"University of Illinois Urbana-Champaign",
		"University of California, Los Angeles",
		"Lapis Labs",
		"California Institute of Technology",
		"Sybil",
		"Carnegie Mellon University",
		"RTX BBN Technologies",
		"University of Newcastle",
		"Keio University",
		"Ariel University",
		"Arizona State University",
		"xAI"
	];

	const equal_contribs = [
		["∗", "First co-authors"],
		["†", "Second co-authors"],
		["‡", "Third co-authors"],
		["∗∗", "Equal advising"],
	]
	let breakAuthorIndices = isMobile ? [1, 53] : [1, 4, 9, 13, 18, 22, 27, 32, 37, 42, 47,50, 53]

	const author_component = <div>
		<p className='header__member'>
			{authors.map((author, index) => (
				<span style={{ color: "#3A6B65" }} key={index}>
					{author.bioLink && <a href={author.bioLink} target="_blank">{author.name}</a>}
					{!author.bioLink && <span>{author.name}</span>}
					{author.contrib && <sup>{author.contrib}</sup>}
					<sup>{author.affiliation}</sup>

					{index !== authors.length - 1 && ", "}
					{breakAuthorIndices.includes(index) && <br />}
				</span>
			))}
		</p>
		<p className='header__note'>
			{affiliations.map((affiliation, index) => (
				<span key={index}>
					<sup>{index + 1}</sup>{affiliation}
					{index !== affiliations.length - 1 && ", "}
				</span>
			))}
		</p>
		<br />
		<p className='header__equal_contribution'>
			{equal_contribs.map((e, index) => (
				<span key={index}>
					<sup>{e[0]}</sup>{e[1]}
					{index !== equal_contribs.length - 1 && ", "}
				</span>
			))}
		</p>
	</div>

	const githubLink = "https://github.com/centerforaisafety/wmdp"
	const hfCollectionLink = "https://huggingface.co/collections/cais/wmdp-benchmark-661ed0abb589122164900e0e"
	const paperLink = "https://arxiv.org/abs/2403.03218"
	const WMDP_MCQA_LINK = "https://huggingface.co/datasets/cais/wmdp"
	const WMDP_CORPORA_LINK = "https://huggingface.co/datasets/cais/wmdp-corpora"
	const MMLU_CARVING_CORPORA_LINK = "https://huggingface.co/datasets/cais/wmdp-mmlu-auxiliary-corpora"
	const datasetMenu = (
		<Menu style={{ backgroundColor: "#E7F7F5" }}>
			<Menu.Item key="wmdp_mcs">
				<a target="_blank" rel="noopener noreferrer" href={WMDP_MCQA_LINK} style={{ color: 'black' }}>

					<span className='header__dropdown' style={{ textAlign: 'center' }}>
						<img src="images/hf-logo.png" alt="Hugging Face" style={{ width: '22px', verticalAlign: 'middle' }} /> WMDP Multiple Choice Benchmark
					</span>
				</a>

			</Menu.Item>
			<Menu.Item key="wmdp_corpora">
				<a target="_blank" rel="noopener noreferrer" href={WMDP_CORPORA_LINK} style={{ color: 'black' }}>

					<span className='header__dropdown' style={{ textAlign: 'center' }}>
						<img src="images/hf-logo.png" alt="Hugging Face" style={{ width: '22px', verticalAlign: 'middle' }} /> WMDP Unlearning Corpora
					</span>
				</a>

			</Menu.Item>
			<Menu.Item key="mmlu_carving">
				<a target="_blank" rel="noopener noreferrer" href={MMLU_CARVING_CORPORA_LINK} style={{ color: 'black' }}>
					<span className='header__dropdown' style={{ textAlign: 'center' }}>
						<img src="images/hf-logo.png" alt="Hugging Face" style={{ width: '22px', verticalAlign: 'middle' }} /> MMLU Auxiliary Corpora
					</span>
				</a>

			</Menu.Item>
		</Menu>
	);

	return (
		<div>
			<Title level={1} className='header__title'>
				The WMDP Benchmark:{isMobile ? <br /> : " "}Measuring and Reducing<br />Malicious Use With Unlearning
			</Title>

			<Collapse ghost className="custom-collapse" bordered={false} onChange={onCollapseChange}>
				<Panel header={
					<Button ghost className="customDashedButton">
						<div className='header__button' style={{ textAlign: 'center', color: 'black' }}>
							<span >The WMDP Team</span>
							<span style={{ marginLeft: "5px" }}>{activeKey.includes('1') ? <CaretUpOutlined style={{ color: 'black' }} /> : <CaretDownOutlined style={{ color: 'black' }} />}</span>
						</div>

					</Button>
				} key="1" showArrow={false}>
					<div style={{ margin: "0 auto", maxWidth: "1024px" }} >
						{author_component}
					</div>
				</Panel>
			</Collapse>

			<div style={{ alignItems: 'center', textAlign: 'center', marginTop: '20px' }}>
				<Space>

					<Button ghost className='customDashedButton' href={paperLink} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black' }}>
							<FileOutlined /> Paper
						</span>
					</Button>
					<Button ghost className='customDashedButton' href={githubLink} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black' }}>
							<GithubOutlined /> GitHub
						</span>
					</Button>
					<Button ghost className='customBlogButton' href={hfCollectionLink} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
							<img style={{ width: "25px", marginRight: "5px", alignSelf: 'center' }} src="images/hf-logo.png" />Collection
						</span>
					</Button>
					{/* <Button ghost className='customDashedButton' href={hfCollectionLink} target="_blank">
						<span className='header__button' style={{ color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
							<img style={{ width: "25px", marginRight: "5px", alignSelf: 'center' }} src="images/hf-logo.png" alt="Hugging Face Collection" />Hugging Face
						</span>
					</Button> */}
					{!isMobile && <Button ghost className='customBlogButton' href={"https://www.safe.ai/blog/wmdp-benchmark"} target="_blank">
						<span className='header__button' style={{ textAlign: 'center', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
							<img style={{ width: "18px", marginRight: "5px", alignSelf: 'center' }} src="https://assets-global.website-files.com/63fe96aeda6bea77ac7d3000/63fe9a72750bc67b129df210_CAIS%20Logo.svg" />Blog
						</span>
					</Button>}
				</Space>
				{isMobile && <Button ghost className='customBlogButton' href={"https://www.safe.ai/blog/wmdp-benchmark"} style={{ marginTop: "2vh" }} target="_blank">
					<span className='header__button' style={{ textAlign: 'center', color: 'black', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
						<img style={{ width: "18px", marginRight: "5px", alignSelf: 'center' }} src="https://assets-global.website-files.com/63fe96aeda6bea77ac7d3000/63fe9a72750bc67b129df210_CAIS%20Logo.svg" />Blog
					</span>
				</Button>}

			</div>

		</div>
	);
};

export default HeaderPage;
