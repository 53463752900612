import React, { useState } from 'react';
import { Typography, Menu, Divider, Col, Row } from 'antd';
import MultipleChoiceTable from './DatasetTable'

const { Title, Paragraph } = Typography;

const Results = () => {
    return (
        <div>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem', marginTop: "5%", fontSize: "3vh" }}>
                <img src="logo.svg" alt="Logo" style={{verticalAlign: 'middle', height: '3vh' }} /> WMDP Multiple Choices Dataset
            </Title>

            <MultipleChoiceTable />
            <Divider />
            <Title level={2} style={{ textAlign: 'center', marginBottom: '2rem', fontSize: "3vh" }}>
                Design Choices for WMDP MCQs
            </Title>

            <Row gutter={[20, 20]} style={{ marginTop: "2vh", alignItems: 'stretch' }}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Title underline level={4} style={{ textAlign: 'center' }} >Hazard Levels of Knowledge</Title>
                    <div style={{ width: "95%" }}>
                        <img src='images/concentric_circles.svg' alt='outline' />
                    </div>
                    <Paragraph style={{ fontSize: "2vh", marginTop: "5%" }}>
                        We aim to directly unlearn hazards in
                        the <span style={{ color: 'red' }}>red category</span> by evaluating and removing knowledge from the <span style={{ color: '#DAA520' }}>yellow category</span>, while retaining
                        as much knowledge as possible in the <span style={{ color: 'green' }}>green category</span>.
                    </Paragraph>

                </Col>
                <Col xs={0} sm={0} md={1} lg={1} xl={1}> {/* Add this column for the divider only on larger screens */}
                    <Divider type="vertical" style={{ height: '100%' }} />
                </Col>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Title underline level={4} style={{ textAlign: 'center' }}>Data Generation Processes</Title>
                    <div >
                        <img src='images/three_panel.svg' alt='outline' />
                    </div>
                    <Paragraph style={{ fontSize: "2vh", marginTop: "8%" }}>
                        To benchmark hazardous capabilities without
                        releasing sensitive information, we develop questions that are precursors, neighbors, and emulations
                        of real-world hazardous information.
                    </Paragraph>
                </Col>
            </Row>



        </div>
    );
};

export default Results;
