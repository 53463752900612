import React from 'react';
import { Typography, Row, Col, Card } from 'antd';
import Citation from './citation';

const { Title, Paragraph, Text, Link } = Typography;

const Home = () => {
	return (
		<div style={{ marginTop: "2vh" }}>
			<div className="page-container">
				<Row gutter={[20, 20]} style={{ marginTop: "2v" }}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<div id='abstract' className='section abstract'>
							<Title level={2} style={{ textAlign: 'center' }} >Introduction</Title>
							<Paragraph className='body__paragraph'>
								The <strong>W</strong>eapons of <strong>M</strong>ass <strong>D</strong>estruction <strong>P</strong>roxy (WMDP) benchmark is a dataset of <span style={{ fontStyle: 'italic' }}>3,668</span> multiple-choice questions surrounding hazardous knowledge in
								<span style={{ color: '#689F38', whiteSpace: 'nowrap' }}>
									<img src="images/bio_icon.svg" alt="Biosecurity Icon" style={{ verticalAlign: 'middle', height: '2vh', width: '2vh', marginLeft: '1vh', marginRight: '0.3vh' }} />
									biosecurity
								</span>,
								<span style={{ whiteSpace: 'nowrap' }}>
									<img src="images/cyber_icon.svg" alt="Cybersecurity Icon" style={{ verticalAlign: 'middle', height: '2vh', width: '2vh', marginLeft: '1vh', marginRight: '0.3vh' }} />
									<span style={{ color: '#548CA8' }}>cybersecurity</span>
								</span>, and
								<span style={{ color: '#7B6F94', whiteSpace: 'nowrap' }}>
									<img src="images/chem_icon.svg" alt="Chemical Security Icon" style={{ verticalAlign: 'middle', height: '2vh', width: '2vh', marginLeft: '1vh', marginRight: '0.3vh' }} />
									chemical security
								</span>. WMDP serves as both a proxy evaluation for hazardous knowledge in large language models (LLMs) and a benchmark for unlearning methods to remove such knowledge. <br /> <br />
								To guide progress on mitigating risk from LLMs, we develop RMU, a state-of-the-art unlearning method which reduces model performance on WMDP while maintaining general language model capabilities.
							</Paragraph>
						</div>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<div>
							<img src='images/dataset.svg' alt='outline' />
						</div>
					</Col>
				</Row>
			</div>

			<div style={{ backgroundColor: "#E7F7F5" }}>
				<div className="page-container" style={{ paddingTop: '2vh' }}>
					<Title level={2} style={{ textAlign: 'center' }} >Evaluating Risk From LLMs</Title>
					<Row gutter={[20, 20]} style={{ marginTop: "2vh" }}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<Card style={{ backgroundColor: 'white', borderRadius: '20px', maxHeight: "90%" }}>
								<div>
									<img src='images/three_panel.svg' alt='outline' style={{ width: '100%', height: 'auto', maxHeight: "90%" }} />
								</div>
							</Card>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12}>
							<div id='abstract' className='section abstract'>
								<Paragraph className='body__paragraph' style={{ marginTop: "1%", marginLeft: '1vh' }}>
								To measure risks of malicious use, government institutions and major AI labs are developing evaluations for hazardous capabilities in LLMs. However, current evaluations are private and are limited to a narrow range of misuse scenarios. To fill these gaps, we publicly release WMDP, an expert-written dataset which measures how LLMs could aid malicious actors in developing biological, cyber, and chemical attack capabilities. To avoid releasing sensitive and export-controlled information, we collect questions that are precursors, neighbors, and components of the hazardous knowledge we wish to remove.								</Paragraph>
							</div>
						</Col>
					</Row>
				</div>
			</div>

			<div className="page-container">
				<Title level={2} style={{ textAlign: 'center', paddingTop: '2%' }} >Mitigating Risk from LLMs</Title>

				<Row gutter={[20, 20]} style={{ marginTop: "2vh" }} justify="center">
					<Col xs={24} sm={24} md={24} lg={18} xl={18}>
						<figure style={{ textAlign: 'center' }}>
							<img style={{ maxWidth: '90%', height: 'auto' }} src='images/pipeline.svg' alt='outline' />
						</figure>
						<Paragraph className='body__paragraph' style={{ marginTop: "2vh" }}>
							On closed source API-access models, such as GPT-4 and Gemini, adversaries can deploy adversarial attacks or harmful API-finetuning to bypass models’ refusal training and unlock their knowledge. Fortunately, model providers have leverage, as they may apply safety interventions before serving the model. In particular, providers may perform machine unlearning to directly remove hazardous knowledge from models. Unlearned models have higher inherent safety: even if they are jailbroken, unlearned models lack the knowledge to be repurposed for malicious use.
						</Paragraph>

						<Row gutter={[20, 20]} style={{ marginTop: "2vh" }}>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<div id='abstract' className='section abstract'>
									<Paragraph className='body__paragraph' style={{ marginTop: "1vh" }}>
										To guide progress on unlearning, we develop RMU, a state-of-the-art method inspired by <a href="https://www.ai-transparency.org/" target="_blank" rel="noopener noreferrer">representation engineering</a>, which improves unlearning precision: removing dangerous knowledge while preserving general model capabilities. RMU significantly reduces model performance on WMDP, while mostly retaining general capabilities on MMLU and MT-Bench, suggesting that concrete progress in unlearning is feasible.								</Paragraph>
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
									<div>
										<img src='images/main_results.png' alt='outline' style={{ width: '95%', height: 'auto', maxHeight: "100%" }} />
									</div>
							</Col>
						</Row>


						<Paragraph style={{ fontSize: '11px', lineHeight: '1.6', marginTop: '2rem' }}>
							<Title level={4}>Citation:</Title>
							<div className="citation-block">
								<Citation />
							</div>
						</Paragraph>
					</Col>
				</Row>
			</div>

		</div>
	);
};


export default Home;
